<template>
    <div>
        <div class="modal-backdrop fade show"></div>
        <!-- Custom field modal -->
        <div class="modal custom-fields show" id="new-user-modal" style="display: block">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content" v-on-click-away="closeModal">
                    <div class="modal-header">
                        <div class="d-flex content-center">
                            <div>
                                <h4 class="modal-title text-uppercase">{{ isEditMode ? $t("Edit Category") : $t("Add Category") }}</h4>
                            </div>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <form @submit.prevent="submitCategory">
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="category_name">{{ $t("Category Name") }}*</label>
                                <input
                                        type="text"
                                        :class="{ 'has-error': errors.category_name.length }"
                                        id="category_name"
                                        v-model="form.category_name"
                                        class="form-control"
                                        :placeholder="$t('Category Name')"
                                        maxlength="50"
                                        ref="category_name"

                                />
                                <div class="error-message" v-text="errors.category_name"></div>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <a href="#" class="button semi-button-info text-capitalize" @click.prevent="closeModal()">{{ $t("back") }}</a>
                            <submit-button :click="submitCategory" :loading="isLoading">{{ isEditMode ? $t("Update") : $t("Add") }}</submit-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import client from "../../../app/api/Client";

export default {
    props: {
        category: {
            required: false,
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            form: {
                id: null,
                category_name: '',

            },
            errors: {
                category_name: '',
            },
            isLoading: false,
        }
    },
    computed: {
        isEditMode() {
            return !!this.category.id;
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeCreateModal');
        },

        async submitCategory() {
            if (this.isValid()) {
                this.isLoading = true;
                try {
                    let {data: {data, message}} = await client().post(`company/setting/category/save`, this.form);
                    this.$toast.success(this.$t(message));
                    this.$emit('reloadCategory');
                    this.closeModal();
                } catch (e) {
                    if (typeof e === 'object') {
                        if (e.response.status === 422) {
                            this.errors = _helper.serializeValidationMessage(e.response.data.message);
                        }
                        // else {
                        //     this.$toast.error(this.$t(e.response.data.message));
                        // }
                    } else {
                    }
                }
                this.isLoading = false
            }
        },

        isValid() {
            this.errors.category_name = "";

            this.form.category_name = this.form.category_name.replace(/\s+/g, ' ');
            if (this.form.category_name.length < 1) {
                this.errors.category_name = this.$t("Please write category name.");
                return false;
            } else if (this.form.category_name.length > 50) {
                this.errors.category_name = this.$t("Please input category name within 50 characters.");
                return false;
            }

            return true;
        }
    },
    mounted() {
        if (this.isEditMode) {
            this.form.id = this.category.id;
            this.form.category_name = this.category.name;
        } else {
            this.form.id = null;
            this.form.category_name = '';
        }

        this.$nextTick(()=>{
           this.$refs.category_name.focus();
        });
    },
    created() {
    }
};
</script>
